import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const RecentPosts = () => {
  const { recentPosts } = useStaticQuery(graphql`
    query WordPressRecentPost {
      recentPosts: allWpPost(sort: { fields: [date], order: DESC }, limit: 3) {
        nodes {
          id
          excerpt
          uri
          date(formatString: "MMMM DD, YYYY")
          month: date(formatString: "MMMM")
          title
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FIXED
                    width: 62
                    height: 50
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  if (!recentPosts?.nodes || recentPosts.nodes === 0) return null

  let postMonth = ""
  let renderHeader = false
  return (
    <div className="recentposts" key="recent-post-wrapper">
      {recentPosts.nodes.map((post, i) => {
        if (postMonth !== post.month) {
          postMonth = post.month
          renderHeader = true
        } else {
          renderHeader = false
        }
        return (
          <div key={post.id + "-title"}>
            {renderHeader && (
              <h3
                key={post.id + "-title"}
                className="block py-2 mb-2 text-lg font-semibold border-b-2 font-Lato text-themeAccent border-themeAccent"
              >
                {post.month}
              </h3>
            )}
            <ul key={post.id + "-recent-ul"} className="flex flex-row py-3">
              <li key={post.id + "-image-li"}>
                <GatsbyImage
                  key={post.id + "-image"}
                  image={
                    post?.featuredImage?.node?.localFile?.childImageSharp
                      ?.gatsbyImageData
                  }
                  alt={post?.featuredImage?.node?.altText || ""}
                />
              </li>
              <li
                key={post.id + "-body-li"}
                className="items-center justify-center mb-2 ml-1"
              >
                <div key={post.id + "-body-article"}>
                  <div key={post.id + "-body-header"}>
                    <h2 className="-mt-2" key={post.id + "-body-h2"}>
                      <Link
                        to={`/blog${post.uri}`}
                        key={post.id + "-body-link"}
                      >
                        <span
                          key={post.id + "-body-span-1"}
                          className="text-xs tracking-wider uppercase transition duration-300 ease-in-out delay-150 font-PTSerif text-themePrimary hover:text-themeAccent"
                          style={{ lineHeight: "90%" }}
                        >
                          {parse(post.title)}
                        </span>
                      </Link>
                    </h2>
                    <p
                      key={post.id + "-body-p"}
                      className="pb-1 text-xs font-Lato text-themeAccent"
                    >
                      <span key={post.id + "-body-span"}>{post.date}</span>
                    </p>
                  </div>
                  <div
                    key={post.id + "-body-section"}
                    className="text-sm font-Lato text-themePrimary line-clamp-2"
                  >
                    {parse(post.excerpt, {
                      replace: ({ attribs }) =>
                        attribs && attribs.class === "read-more" && <></>,
                    })}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        )
      })}
    </div>
  )
}

export default RecentPosts
