import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Categories from "../components/Categories"
import RecentPosts from "../components/RecentPosts"

const BlogIndex = ({
  data,
  pageContext: {
    nextPagePath,
    previousPagePath,
    currentPageBase,
    totalPages,
    currentPage,
    paginationArray,
  },
}) => {
  const posts = data.allWpPost.nodes
  const isFirst = currentPage === 1
  const isLast = currentPage === totalPages
  const seoData = data.archiveSeo.seo

  if (!posts.length) {
    return (
      <Layout>
        <Seo seoData={seoData} />
        <div className="w-10/12 mx-auto my-16 text-2xl font-semibold text-gray-600">
          <p>
            No blog posts found. Please add posts to your site and they'll
            appear here!
          </p>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <h1 className="hidden">Blog Archive</h1>
      <Seo seoData={seoData} />

      <div className="my-16 text-gray-600">
        <div>
          <h2 className="text-5xl font-bold tracking-wide text-center font-PTSerif text-themePrimary">
            Pomares Law Group in the News
          </h2>
          <p className="my-6 text-lg text-center font-Lato text-themePrimary">
            Stay current on leading law news and <br />
            practices by visiting our News posts periodically.
          </p>
        </div>
        <div className="all-blogs">
          <div className="main-content">
            <ul>
              {posts.map(post => {
                const title = post?.title
                const featuredImage = {
                  fluid:
                    post.featuredImage?.node?.localFile?.childImageSharp
                      ?.gatsbyImageData,
                  alt: post.featuredImage?.node?.alt || ``,
                }

                return (
                  <li key={post.uri} className="pb-8">
                    <ul className="flex flex-col md:flex-row">
                      <li key={post.uri + "-image"} className="w-full md:w-2/6">
                        <GatsbyImage
                          image={featuredImage.fluid}
                          alt={featuredImage.alt}
                        />
                      </li>
                      <li
                        key={post?.uri + "-body"}
                        className="w-full md:min-w-4/6 md:ml-20"
                      >
                        <header>
                          <h2 className="mt-2">
                            <Link to={`/blog${post?.uri}`}>
                              <span className="text-base font-bold leading-none tracking-wider uppercase transition duration-300 ease-in-out delay-150 font-PTSerif text-themeAccent hover:text-themePrimary">
                                {parse(title)}
                              </span>
                            </Link>
                          </h2>
                          <p className="pb-2 text-sm font-Lato text-themePrimary">
                            Posted by{" "}
                            <span>{`${post?.author?.node?.firstName} ${post?.author?.node?.lastName}`}</span>
                          </p>
                        </header>
                        <div className="text-sm font-Lato text-themePrimary">
                          {parse(post?.excerpt, {
                            replace: ({ name, attribs, children }) => {
                              if (
                                name === "a" &&
                                attribs["href"].search(
                                  process.env.GATSBY_PROJ_URL
                                ) > 0
                              ) {
                                return (
                                  <Link
                                    to={attribs["href"].replace(
                                      "blog_admin/",
                                      ""
                                    )}
                                  >
                                    {children}
                                  </Link>
                                )
                              }

                              if (attribs && attribs.class === "read-more") {
                                return <></>
                              }
                            },
                          })}
                        </div>
                      </li>
                    </ul>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="sidebar">
            <RecentPosts />
            <Categories />
          </div>
        </div>
      </div>
      <div>
        <ul className="flex flex-wrap items-center justify-center p-0 list-none">
          {!isFirst && (
            <li>
              <Link
                to={previousPagePath}
                rel="prev"
                className="text-themeBlue-200 hover:text-themeBlue-600"
                style={{
                  marginTop: "0.1rem",
                  marginBottom: "0.1rem",
                  padding: "0.5rem",
                }}
              >
                {"<< Prev"}
              </Link>{" "}
            </li>
          )}
          {paginationArray.map((x, i) => {
            return (
              <li
                key={`pagination-number${x}+${i}`}
                style={{
                  margin: 0,
                }}
              >
                {x === "..." ? (
                  x
                ) : (
                  <Link
                    to={`${x === 1 ? currentPageBase : currentPageBase + x}`}
                    className={`${
                      x === currentPage
                        ? "text-white bg-themeBlue-200 hover:bg-themeBlue-600"
                        : "text-themeBlue-200 hover:text-themeBlue-600"
                    }
                `}
                    style={{
                      marginTop: "0.1rem",
                      marginBottom: "0.1rem",
                      padding: "0.5rem",
                      textDecoration: "none",
                    }}
                  >
                    {x}
                  </Link>
                )}
              </li>
            )
          })}
          {!isLast && (
            <li>
              <Link
                to={nextPagePath}
                rel="next"
                className="text-themeBlue-200 hover:text-themeBlue-600"
                style={{
                  marginTop: "0.1rem",
                  marginBottom: "0.1rem",
                  padding: "0.5rem",
                }}
              >
                {"Next >>"}
              </Link>
            </li>
          )}
        </ul>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        author {
          node {
            firstName
            lastName
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  width: 330
                  height: 190
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    archiveSeo: wpPage(slug: { eq: "blog" }) {
      seo {
        fullHead
        schema {
          raw
        }
      }
    }
  }
`
